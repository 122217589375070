$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
);

@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

@mixin container() {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background-color: $gray-100;

  min-height: 100%;
}

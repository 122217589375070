@import '../env';

.notification__container {
  .notification__message {
    z-index: 10;
    max-width: 500px;
    position: absolute;
    top: 2.5rem + map-get($spacers, 2);
    right: map-get($spacers, 3);
    font-size: 1.1rem;
  }

  .notification__icon {
    margin-right: $spacer;
  }

  .notification__message.notification__error {
    background-color: $red;
    color: $white;
  }

  .notification__message.notification__info {
    background-color: $cyan;
    color: $white;
  }
}

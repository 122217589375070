@import './env';

.home__container {
  @include container();
}

.home__body {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: map-get($grid-breakpoints, lg)) {
    flex-direction: row;
  }
}

.home__card {
  margin: map-get($spacers, 3);
  margin: map-get($spacers, 3);
}

.home__action {
  margin-right: map-get($spacers, 3);
}

.home__body .home__join-form {
  margin-bottom: 0;
}

.delete-confirm__action {
  margin-right: map-get($spacers, 2);
}

.home__join .home__join-code {
  width: 12rem;
}

.home__join .join__button {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
